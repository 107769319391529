import { graphql } from 'gatsby';
import React, { useState } from 'react';
import { useKey } from 'react-use';

import { md2react } from 'helpers/parser';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import DownloadModal from 'components/organisms/DownloadModal';
import ResourceGrid from 'components/molecules/ResourceGrid';
import ResourceTypeTag from 'components/molecules/ResourceTypeTag';
import CtaSection from 'components/organisms/CtaSection';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';
import * as WatchIcon from 'images/watch.inline.svg';

const DownloadContent = ({
  title,
  description,
  readTime,
  heroImage,
  body,
  onDownloadClick, // not avaiable from preview
}) => {
  return (
    <>
      <div className={`bg-yellow bg-opacity-15 pt-32 md:pt-48 pb-48`}>
        <div className="flex flex-col items-center px-6 u-container lg:px-md lg:flex-row">
          <div className="w-full mb-16 lg:w-1/2 lg:mb-0">
            <ResourceTypeTag type="download" />
            <h1 className="u-h1 mt-6 md:mt-8 mb-3.5 w-article max-w-full">
              {title}
            </h1>
            <p className="mb-8 text-xl leading-8 tracking-tighter lg:pr-44 text-mid-grey">
              {description}
            </p>
            <button
              className="mb-16 u-btn u-btn--dark focus:outline-none"
              onClick={onDownloadClick}
            >
              Download now
            </button>
            {!!readTime && (
              <div className="flex items-center text-sm font-bold tracking-tighter uppercase">
                <WatchIcon className="h-3.5 w-auto mr-2.5" />
                <div>{readTime}</div>
              </div>
            )}
          </div>
          <div className="flex items-center justify-center w-full lg:w-1/2">
            <img
              src={heroImage}
              alt={title}
              className="h-auto rounded-r-5 xl:w-89 lg:w-110"
            />
          </div>
        </div>
      </div>

      {body && (
        <div className="px-6 u-section u-container lg:px-md">
          <div className="prose-xl lg:w-8/12">{md2react(body)}</div>
        </div>
      )}
    </>
  );
};

export const DownloadPreview = ({ entry }) => {
  return <DownloadContent {...entry.get('data').toJS()} />;
};

const DownloadPage = ({
  data: {
    markdownRemark: { frontmatter: download, rawMarkdownBody },
    page: { frontmatter: page },
    related,
  },
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  useKey('Escape', () => setModalOpen(false));

  const { seo, title, description } = download;

  // used by both DownloadModal and ResourceGrid
  const resources = related.edges.map(({ node }) => {
    const { title, slug, heroImage, description } = node.frontmatter || {};
    return {
      title,
      url: `/downloads/${slug}`,
      thumbnailUrl: heroImage,
      type: 'download',
      excerpt: description,
    };
  });

  return (
    <Layout>
      <SEO
        title={seo?.title || title}
        description={seo?.description || description}
        openGraphImage={seo?.openGraphImage}
      />

      <DownloadModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        download={download}
        relatedDownloads={resources}
        downloadModal={page?.downloadModal}
      />

      <DownloadContent
        {...download}
        body={rawMarkdownBody}
        onDownloadClick={() => setModalOpen(true)}
      />

      <NewsletterCtaSection
        headline={page?.newsletterCta?.headline}
        background="bg-yellow"
      />

      {resources.length > 0 && (
        <ResourceGrid
          lead="Related Downloads"
          cols={3}
          videoIconInverted
          noTag
          resources={resources}
        />
      )}

      <CtaSection headline={page?.cta?.headline} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query DownloadByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date
        file
        description
        readTime
        heroImage
        seo {
          title
          description
          openGraphImage
        }
      }
      rawMarkdownBody
    }
    page: markdownRemark(fileAbsolutePath: { regex: "/pages/downloads.md/" }) {
      frontmatter {
        downloadModal {
          title
          body
        }
        newsletterCta {
          headline
        }
        cta {
          headline
        }
      }
    }
    related: allMarkdownRemark(
      filter: {
        id: { ne: $id }
        frontmatter: {
          templateKey: { eq: "download" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            heroImage
            description
          }
        }
      }
    }
  }
`;

export default DownloadPage;
